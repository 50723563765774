import React from "react";

export const Promo = () => {
    return (
        <section className="promo-area section-ptb bg-4 bg-primary">
            <div className="container ptb-30">
                <div className="row text-center">
                    <div className="col-12">
                        <div className="heading">
                            <h1 className="text-white mb-25">Estrategias para Manejar el Recurso Humano</h1>
                            <p className="text-white mb-30">En un mundo cada vez más conectado, implementar una estrategia digital es esencial. Nos enfocamos en que todos los procesos de recursos humanos, desde la contratación hasta la evaluación del desempeño, sean accesibles en línea y ajustados al contexto costarricense.</p>
                            <a className="btn btn-light" href="#contact-us">Iniciemos</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}