import React from "react";

export const HeroText = ({title, subtitle, content, list, button}) => {

    return (
        <div className="hero-text">
            <div className="hero-text">
                <h3 className="mb-5">{subtitle}</h3>
                <h1 className="mb-25">{title}</h1>
                <p className="mb-30 mw-5">{content}</p>

                {list !== undefined
                    ? <ul className="list-group pl-20 mb-30">  {list.map((item, index) => <li key={index}>{item}</li>)} </ul>
                    : ''
                }
                {button
                    ? <a href={button.href} className={`btn ${button.className}`}>{button.title}</a>
                    : ''
                }
            </div>
        </div>
    );
}