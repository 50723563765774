import React from "react";

export const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-widget-area bg-light ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-5 mb-sm-40">
                                <div className="footer-widget about">
                                    <div className="footer-logo mb-20">
                                        <a href="index.html"><img src="assets/img/logo.png" alt="RNR"/></a>
                                    </div>
                                    <div className="widget-content">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright bg-white ptb-15">
                    <div className="container d-sm-flex">
                        <p className="mb-0">Copyrights &copy; 2024 Todos los derechos reservados <a href="https://serviciosintegralesjvja.com" target="_blank" rel="noreferrer"><strong>Servicios Integrales JV&JA</strong></a></p>
                    </div>
                </div>
            </footer>
        </>
    );
}