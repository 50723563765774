import {React, useState, useEffect} from "react";


export const Header = () => {
    const [isSticky, setIsSticky] = useState(false);

    const handleClick = (evt) => {
        evt.preventDefault();
        console.log(evt.target);
        let element = document.querySelector(evt.target.getAttribute('href'));

        element?.scrollIntoView({
            behavior: 'smooth'
        });

        document.querySelector('#hamburger-menu').click();
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY < 1) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }
        };

        // Agregar el evento scroll
        window.addEventListener('scroll', handleScroll);

        // Limpiar el evento scroll cuando el componente se desmonte
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const baseClasses = 'navbar navbar-light navbar-expand-lg'; // Clases que siempre estarán presentes
    const stickyClass = isSticky ? 'is-sticky' : '';

    return (
        <header className="position-fixed w-100">
            <nav id="active-sticky" className={`${baseClasses} ${stickyClass}`}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src="assets/img/logo.png" alt="rnr"/>
                    </a>
                    <button className="navbar-toggler navber-toggler-right" data-toggle="collapse" data-target="#navbarToggler" id="hamburger-menu">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a href="#" className="nav-link"></a>
                            </li>
                            <li className="nav-item">
                                <a href="#home" className="nav-link" onClick={handleClick}>Inicio</a>
                            </li>
                            <li className="nav-item">
                                <a href="#about-us" className="nav-link" onClick={handleClick}>Nosotros</a>
                            </li>
                            <li className="nav-item">
                                <a href="#process" className="nav-link" onClick={handleClick}>Proceso</a>
                            </li>
                            <li className="nav-item d-none">
                                <a href="#" className="nav-link">Clientes</a>
                            </li>
                            <li className="nav-item">
                                <a href="#faqs" className="nav-link" onClick={handleClick}>FAQs</a>
                            </li>
                            <li className="nav-item">
                                <a href="#contact-us" className="nav-link" onClick={handleClick}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}