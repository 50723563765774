import React from "react";
import './slider.scss';

export const Slider = () => {
    return (
        <section className="slider-area h-100 h-sm-auto bg-1" id="home">
            <div className="container h-100 h-sm-auto d-flex flex-row align-items-center flex-wrap flex-md-nowrap">
                <div className="caption">
                    <h5 className="display-3 mb-10">¡Transforma y gestiona</h5>
                    <h5 className="display-3 mb-10">el recurso humano de tu empresa! </h5>
                    <h1 className="display-1 mb-25">SYS HUMAN RH</h1>
                    <p className="mb-30">Con nuestro sistema, optimizarás la gestión de tu equipo, mejorarás el flujo de información y potenciarás los procesos de control y registro de cada empleado. </p>
                    <a href="#contact-us" className="btn btn-primary">Iniciemos</a>
                    <a href="https://player.vimeo.com/video/58363288" className="btn btn-outline-light outline-primary ml-25 d-none" data-autoplay="true" data-vbtype="video"><i className="ti-control-play"></i> Watch Our Video</a>
                </div>
                <div className="image-layer ml-md-auto align-self-md-center align-self-start mr-minus-80">
                    <img src="/assets/img/slider/recurso-humano.jpg" alt="Recursos Humanos" width="677" />
                </div>
            </div>
        </section>
    );
}