import React from 'react';
import emailjs from '@emailjs/browser';
import {useRef} from "react";

import './contact-us.scss';

export const ContactUs = () => {
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        const inputs = document.querySelectorAll('input, textarea');

        for(let input of inputs) {
            if(input.value === "" || input.length < 0) {
                return false;
            }
        }

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_PUBLIC_KEY
        )
            .then((result) => {
                if(result.text === "OK") {
                    document.querySelector('#contact_form').reset();
                }
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <section className="contact-area section-ptb white-bg" id="contact-us">
            <div className="container">
                <div className="row mb-70 d-flex align-items-center">
                    <div className="col-12 col-md-6 col-lg-7 mb-sm-30">
                        <div className="contact-form">
                            <h2>Dejanos un mensaje</h2>
                            <hr className="line line-sm mb-40"/>
                            <form className="form-group" id="contact_form" onSubmit={handleSubmit} ref={form}>
                                <input type="hidden" id="website" name='website' value="https://sys-human.com" />
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <input className="form-control" type="text" name="name" id="name" placeholder="Nombre" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <input className="form-control" type="email" name="email" id="email" placeholder="Email"/>
                                    </div>
                                </div>
                                <input className="form-control" type="text" name="subject" id="subject" placeholder="Asunto"/>
                                <textarea className="form-control" name="message" id="message" rows="3" placeholder="Mensaje"></textarea>
                                <div className="help-block with-errors"></div>
                                <button className="btn btn-primary mt-5" type="submit" id="contact_submit" data-complete-text="Well done!">Enviar</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1 col-lg-4">
                        <div className="contact-info">
                            <h2>Contactanos</h2>
                            <hr className="line line-sm"/>
                            <ul className="list-unstyled">
                                <li><span>Teléfono:</span> <a href="tel:+50670359524">+506 7035 9524</a></li>
                                <li><span>Email:</span> <a href="mailto:soporte@sys-human.com">soporte@sys-human.com</a></li>
                                <li><span>Whatsapp:</span> <a href="https://wa.me/50670359524?&text=Hola, Quisera más información" target='_blank' rel='noreferrer'>+506 7035 9524</a></li>
                            </ul>
                            <hr className="line max-w mb-20"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}